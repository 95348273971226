<template>
  <b-modal
    id="cms-create-floating-menus"
    title="New Floating Menu"
    no-close-on-backdrop
    @ok="onOk"
  >
    <ValidationObserver ref="cmsForm" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider
          v-slot="{ errors }"
          name="title"
          :rules="{
            required: false,
          }"
        >
          <b-form-group label="Title">
            <b-form-input
              id="title"
              v-model="cmsFloatingMenuForm.title"
              :state="errors[0] ? false : null"
            ></b-form-input>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          name="image_src"
          :rules="{
            required: true,
          }"
        >
          <b-form-group label="Image">
            <CmsFileSelect
              :value="cmsFloatingMenuForm.imageSrc"
              :state="errors[0] ? false : null"
              @update="(value) => (cmsFloatingMenuForm.imageSrc = value || '')"
            />
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          name="display_condition"
          :rules="{
            required: true,
          }"
        >
          <b-form-group label="Display condition">
            <b-select
              v-model="cmsFloatingMenuForm.displayCondition"
              :state="errors[0] ? false : null"
            >
              <b-select-option
                v-for="(item, index) of conditions"
                :key="index"
                :value="item"
                >{{ item }}</b-select-option
              >
            </b-select>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          name="display_condition"
          :rules="{
            required: true,
          }"
        >
          <b-form-group label="External link?">
            <b-form-checkbox
              v-model="cmsFloatingMenuForm.isExternalLink"
              switch
              :state="errors[0] ? false : null"
              size="lg"
            ></b-form-checkbox>
          </b-form-group>
        </ValidationProvider>
        <b-form-group
          v-if="cmsFloatingMenuForm.isExternalLink"
          label="External link target"
        >
          <b-form-input
            v-model="cmsFloatingMenuForm.externalLinkTo"
            placeholder="https://google.com"
          ></b-form-input>
        </b-form-group>
        <b-form-group v-else label="Internal link target">
          <b-form-input
            v-model="cmsFloatingMenuForm.internalLinkTo"
            placeholder="/dashboard"
          ></b-form-input>
        </b-form-group>
        <ValidationProvider
          v-slot="{ errors }"
          name="position"
          :rules="{
            required: true,
          }"
        >
          <b-form-group label="Position">
            <b-form-input
              id="position"
              v-model="cmsFloatingMenuForm.position"
              :state="errors[0] ? false : null"
            ></b-form-input>
          </b-form-group>
        </ValidationProvider>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  components: {
    CmsFileSelect: () => import('../file-select.vue'),
  },
  data() {
    return {
      cmsFloatingMenuForm: {
        position: 0,
        title: '',
        imageSrc: '',
        displayCondition: 'ALL',
        isExternalLink: false,
        internalLinkTo: '',
        externalLinkTo: '',
      },
      conditions: ['ALL', 'LOGGED_IN', 'NOT_LOGGED_IN'],
    }
  },
  computed: {
    ...mapState({
      isCreatingSiteFloatingMenus: (state) =>
        state.cms.isCreatingSiteFloatingMenus,
    }),
    ...mapGetters(['siteId']),
  },
  methods: {
    ...mapActions(['createCmsFloatingMenus']),
    async onOk(e) {
      e.preventDefault()
      const valid = await this.$refs.cmsForm.validate()
      if (valid) {
        this.onSubmit(() => this.$bvModal.hide('cms-create-floating-menus'))
      }
    },
    async onSubmit(cb) {
      await this.createCmsFloatingMenus({
        siteId: this.siteId,
        floatingMenus: [this.cmsFloatingMenuForm],
      })

      cb()
    },
  },
}
</script>
